import React, { createContext, useContext, useEffect, useState } from "react";
import config from "./config";
import firebase from "firebase/compat/app";

export interface LogMessage {
  type: "error" | "info" | "log" | "warn";
  msg: string;
  timestamp: firebase.firestore.Timestamp;
  objects: any[];
}

interface LogState {
  console: LogMessage[];
}

const LogContext = createContext<LogState>({
  console: [],
});

export const useLog = () => useContext(LogContext);

export const stringifyOnce = (obj: any) => {
  try {
    return JSON.stringify(
      obj,
      function(k, v) {
        return k && v && typeof v !== "number"
          ? Array.isArray(v)
            ? `[${v.length} objects]`
            : "" + v
          : v;
      },
      4
    );
  } catch (err) {
    return "Circular JSON";
  }
};

const msgToString = (obj: any) => {
  if (obj == null) return "null";
  try {
    return stringifyOnce(obj);
  } catch {
    return String(obj);
  }
};

export function Logger({ children }: { children: React.ReactNode }) {
  const [logs, setLogs] = useState<LogMessage[]>([]);
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    // Log messages get slightly obscured, so prefer not to use locally where possible
    if (config.runningLocally || window.location.hash.includes("diagnostic")) {
      setInit(true);
      return;
    }

    // Intercept browser logs, cache them in JS and then report them (if configured)
    var console_log = console.log;
    console.log = (msg: any, ...optionalParams: any) => {
      try {
        if (msg)
          setLogs((logs) =>
            logs.concat([
              {
                type: "log",
                msg: msgToString(msg),
                timestamp: firebase.firestore.Timestamp.now(),
                objects: optionalParams,
              },
            ])
          );
      } catch (err) {}
      if (config.logToConsole) {
        console_log.apply(msg, [msg, ...optionalParams]);
      }
    };

    var console_info = console.info;
    console.info = (msg: any, ...optionalParams: any) => {
      try {
        if (msg)
          setLogs((logs) =>
            logs.concat([
              {
                type: "info",
                msg: msgToString(msg),
                timestamp: firebase.firestore.Timestamp.now(),
                objects: optionalParams,
              },
            ])
          );
      } catch (err) {}
      if (config.logToConsole) {
        console_info.apply(msg, [msg, ...optionalParams]);
      }
    };

    var console_warn = console.warn;
    console.warn = (msg: any, ...optionalParams: any) => {
      try {
        if (msg)
          setLogs((logs) =>
            logs.concat([
              {
                type: "warn",
                msg: msgToString(msg),
                timestamp: firebase.firestore.Timestamp.now(),
                objects: optionalParams,
              },
            ])
          );
      } catch (err) {}
      if (config.logToConsole) {
        console_warn.apply(msg, [msg, ...optionalParams]);
      }
    };

    var console_error = console.error;
    console.error = (msg: any, ...optionalParams: any) => {
      try {
        if (msg)
          setLogs((logs) =>
            logs.concat([
              {
                type: "error",
                msg: msgToString(msg),
                timestamp: firebase.firestore.Timestamp.now(),
                objects: optionalParams,
              },
            ])
          );
      } catch (err) {}
      if (config.logToConsole) {
        console_error.apply(msg, [msg, ...optionalParams]);
      }
    };
    console.info("Config", { ...config, stage: process.env.APP_STAGE });

    setInit(true);
  }, []);

  return (
    <LogContext.Provider value={{ console: logs }}>
      {init && children}
    </LogContext.Provider>
  );
}

export default Logger;
