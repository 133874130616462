import { Button, Typography, Box } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { useAuth, useClaims } from "Auth";
import { Team, TeamScope, TeamScopes } from "Types";
import firebase from "firebase/compat/app";
import { useAlerts } from "Alerts";
import queryString from "query-string";

const db = firebase.firestore();

export function AccountPreamble({ team }: { team: Team | null }) {
  const claims = useClaims();
  const auth = useAuth()!;
  const { prompt } = useAlerts();

  async function editTeamName(team_id: string, old_name: string) {
    const newContact = await prompt(
      "Please enter a new team name.",
      old_name,
      "Edit Team Name"
    );
    if (newContact && newContact !== "" && newContact !== old_name) {
      //
      const update: Partial<Team> = {
        nickname: newContact,
      };
      await db.doc(`teams/${team_id}`).update(update);
    }
  }

  if (claims === undefined) return null;

  let role: TeamScope | undefined = undefined;

  if (claims != null) {
    if (claims.isOwner) role = TeamScopes.owner;
    else if (claims.isAdminOrAbove) role = TeamScopes.admin;
    else if (claims.isUsersManagerOrAbove) role = TeamScopes.manager;
    else if (claims.isFinanceManager) role = TeamScopes.finances;
    else if (claims.isModelsManagerOrAbove) role = TeamScopes.user;
  }

  let copy: {
    link: { label: string; url: string };
    copy: string;
    role: string;
  };

  switch (role) {
    case TeamScopes.owner:
      copy = {
        copy: "Invite your team and publish models to start using Twinbuild with your HoloLens 2 devices.",
        link: {
          label: "Setup Guide",
          url: "https://docs.twinbuild.com/guides/setup-guide",
        },
        role: "Owner",
      };
      break;
    case TeamScopes.admin:
      copy = {
        copy: "Invite your team and publish models to start using Twinbuild with your HoloLens 2 devices.",
        link: {
          label: "Setup Guide",
          url: "https://docs.twinbuild.com/guides/setup-guide",
        },
        role: "Administrator",
      };
      break;
    case TeamScopes.finances:
      copy = {
        copy: `Manage your team's subscription and make changes to your plan. Contact a team administrator to request changes to your role or permissions.`,
        link: {
          label: "Setup Guide",
          url: "https://docs.twinbuild.com/guides/setup-guide",
        },
        role: "Finance Manager",
      };
      break;

    case TeamScopes.manager:
      copy = {
        copy: `Invite your team and publish models to start using Twinbuild with your HoloLens 2 devices. Contact a team administrator of your team to request changes to your role or permissions.`,
        link: {
          label: "Setup Guide",
          url: "https://docs.twinbuild.com/guides/setup-guide",
        },
        role: "Manager",
      };
      break;

    case TeamScopes.user:
      copy = {
        copy: "Read our guide to learn best practices then launch the app to view models in Twinbuild. Contact a team administrator to request changes to your role or permissions.",
        link: {
          label: "Publishing Guide",
          url: "https://docs.twinbuild.com/model-preparation/",
        },
        role: "User",
      };
      break;
    default:
      if (team) {
        // User has no permissions
        copy = {
          copy: "You don't have any permissions assigned in your team. Contact a team administrator to request changes to your role or permissions.",
          link: {
            label: "Setup Guide",
            url: "https://docs.twinbuild.com/guides/setup-guide",
          },
          role: "",
        };
      } else {
        // Trial copy
        const qs = {
          subject: "Trial License Request",
          email: auth.email,
          name: auth.displayName,
          prompt:
            "Please tell us a little about what you're interested in using Twinbuild for and we'll get back to you with information about your trial.",
        };
        copy = {
          copy: "Contact our support team to request a 14 day trial of Twinbuild Pro, or add a seat to your subscription and attach your HoloLens 2 to your account to start using Twinbuild.",
          link: {
            label: "Request Trial License",
            url: `https://twinbuild.com/contact?${queryString.stringify(qs)}`,
          },
          role: "",
        };
      }
  }

  return (
    <>
      <Typography variant="h3" color="primary" gutterBottom sx={styles.title}>
        <strong>Account Dashboard</strong>
      </Typography>
      <Box sx={styles.container}>
        {team && (
          <>
            <Box sx={styles.teamInfo}>
              <Typography variant="h6" color="primary" sx={styles.teamName}>
                {team.nickname}
              </Typography>

              {claims?.isOwner && (
                <Button
                  onClick={() => editTeamName(team.team_id, team.nickname)}
                  color="primary"
                >
                  Edit Name
                </Button>
              )}
            </Box>

            <Typography variant="caption" color="primary">
              {team.subscription_type &&
                team.total_subscription_quantity > 0 && (
                  <>
                    Twinbuild Pro Plan / {team.total_subscription_quantity}{" "}
                    seats
                  </>
                )}
              {team.total_subscription_quantity === 0 && (
                <>No active subscription</>
              )}
            </Typography>
            <Typography variant="body1" sx={styles.roleDescription}>
              {copy.role}
            </Typography>
          </>
        )}

        {team !== undefined && (
          <>
            <Typography variant="body2" gutterBottom sx={styles.copy}>
              {copy.copy}
            </Typography>
            <Button
              sx={styles.button}
              variant="outlined"
              color="primary"
              endIcon={<Launch />}
              target="_blank"
              href={copy.link.url}
            >
              {copy.link.label}
            </Button>
          </>
        )}
      </Box>
    </>
  );
}

export default AccountPreamble;

const styles = {
  container: {
    marginTop: 1.5,
    marginBottom: 4,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  copy: {
    maxWidth: 450,
  },
  button: {
    marginTop: 1.5,
    maxWidth: 250,
  },
  title: {
    marginTop: 0.75,
  },
  teamInfo: {
    width: "100%",
    display: "flex",
  },
  teamName: {
    flexGrow: 1,
  },
  roleDescription: {
    marginBottom: 1.5,
  },
} as const;
