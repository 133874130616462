import {
  AppBar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AuthDialog, useAuth, useClaims } from "Auth";
import { ReactComponent as Logo } from "static/images/wordmark.svg";
import { Link } from "react-router-dom";

import firebase from "firebase/compat/app";
import {
  AccountCircle,
  ExitToApp,
  Help,
  Home,
  HomeWork,
  Menu as MenuIcon,
  MenuBook,
} from "@mui/icons-material";
import { HelpDialog } from "Help";

const auth = firebase.auth();

export default function AccountFrame({
  children,
  required,
  narrow,
}: {
  children: React.ReactNode;
  narrow?: boolean;
  required?: boolean;
}) {
  const user = useAuth();
  const claims = useClaims();

  const [authDialogVisible, setAuthDialogVisible] = useState<boolean>(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState<boolean>(false);
  const [popped, setPopped] = useState<boolean>(false);
  const popRef = useRef<HTMLElement>();
  const [helpVisible, setHelpVisible] = useState<boolean>(false);

  const loaded = user !== undefined && (user === null || claims !== undefined);

  useEffect(() => {
    if (loaded) {
      setHasLoadedOnce(true);
    }
  }, [loaded]);

  return (
    <>
      <HelpDialog open={helpVisible} onClose={() => setHelpVisible(false)} />
      <AuthDialog
        open={required ? user === null : user === null && authDialogVisible}
        onSuccess={() => setAuthDialogVisible(false)}
        onCancel={required ? undefined : () => setAuthDialogVisible(false)}
      >
        Sign in to your Twinbuild account or create a new account with your
        email provider.
      </AuthDialog>
      <AppBar position="sticky">
        <Toolbar sx={styles.toolbar}>
          <IconButton
            edge="start"
            sx={styles.menuButton}
            color="inherit"
            aria-label="menu"
            component={Link}
            to="/"
            size="large"
          >
            <Logo
              style={{
                height: 36,
                width: 36,
                fill: "#ffffff",
              }}
            />
          </IconButton>
          <Typography variant="h6" sx={styles.title}></Typography>
          {user && (
            <Typography variant="caption" color="inherit" sx={styles.userName}>
              {user.email}
            </Typography>
          )}
          {user === null && (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  setAuthDialogVisible(true);
                }}
                sx={styles.signOutbutton}
              >
                Sign In
              </Button>
            </>
          )}
          <IconButton
            sx={styles.hamburger}
            onClick={() => setPopped(true)}
            ref={(r) => {
              if (r) popRef.current = r;
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          {popRef.current && (
            <Popover
              disableScrollLock
              open={popped}
              onClose={() => setPopped(false)}
              anchorEl={popRef.current}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <MenuList sx={styles.menu}>
                <MenuItem
                  onClick={() => {
                    window.location.href = "https://twinbuild.com";
                  }}
                >
                  <ListItemIcon>
                    <Home fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Home</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "https://docs.twinbuild.com";
                  }}
                >
                  <ListItemIcon>
                    <MenuBook fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Docs</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "https://app.twinbuild.com";
                  }}
                >
                  <ListItemIcon>
                    <HomeWork fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Models</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "https://account.twinbuild.com";
                  }}
                >
                  <ListItemIcon>
                    <AccountCircle fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Account</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setHelpVisible(true);
                    setPopped(false);
                  }}
                >
                  <ListItemIcon>
                    <Help fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="button">Help</Typography>
                </MenuItem>

                {user && <Divider />}

                {user && (
                  <MenuItem
                    onClick={() => {
                      auth.signOut();
                      setPopped(false);
                    }}
                  >
                    <ListItemIcon>
                      <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="button">Sign Out</Typography>
                  </MenuItem>
                )}
              </MenuList>
            </Popover>
          )}
        </Toolbar>
      </AppBar>
      {(loaded || (!loaded && !required && hasLoadedOnce)) &&
      (!required || user != null) ? (
        <Box component="div" sx={narrow ? styles.container : styles.full}>
          {children}
        </Box>
      ) : (
        <Box component="div" sx={styles.full} />
      )}
    </>
  );
}

const styles = {
  container: {
    maxWidth: 960,
    m: "auto",
    p: 0.5,
    pt: 4,
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    pl: 1.5,
    pr: 1.5,
    minHeight: { xs: "calc(100vh - 60px)", sm: "calc(100vh - 64px)" },
    position: "relative",
  },
  full: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    minHeight: { xs: "calc(100vh - 60px)", sm: "calc(100vh - 64px)" },
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    width: "min(1000px, 100%)",
    alignSelf: "center",
    display: "flex",
  },
  menuButton: {
    marginRight: 2,
  },
  userName: {
    mr: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  signOutbutton: {
    whiteSpace: "nowrap",
  },
  hamburger: {
    color: "primary.contrastText",
  },
  menu: {
    width: 200,
  },
} as const;
