import { useClaims } from "Auth";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { ModelTokenReference, Team } from "Types";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear, Launch } from "@mui/icons-material";
import { useAlerts } from "Alerts";
import { CopyableTableCell } from "../components/TableCells";
import ms from "ms";
import AccountCollapse from "./AccountCollapse";
import config from "config";

const db = firebase.firestore();

export default function TokensView({ team }: { team: Team }) {
  const teamId = team.team_id;
  const claims = useClaims();
  const canEditTokens = claims?.isInATeam && claims.isModelsManagerOrAbove;

  const [tokens, setTokens] = useState<
    firebase.firestore.QueryDocumentSnapshot<ModelTokenReference>[] | undefined
  >(undefined);

  useEffect(() => {
    if (!canEditTokens) return;

    return db
      .collection(`tokens`)
      .where("team_id", "==", teamId)
      .where("token_duration", ">", ms("2h"))
      .onSnapshot((snapshot) => {
        setTokens(
          snapshot.docs
            .map(
              (doc) =>
                doc as firebase.firestore.QueryDocumentSnapshot<ModelTokenReference>
            )
            .sort((a, b) => b.data().token_expiry - a.data().token_expiry)
        );
      });
  }, [teamId, canEditTokens]);

  if (!canEditTokens) return null;

  return (
    <AccountCollapse
      title="Manage Links"
      description="View or revoke Links that provide access to your models"
    >
      <Table size="small" sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Model
            </TableCell>
            <TableCell id="link" />

            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Last Accessed
            </TableCell>
            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Expires
            </TableCell>
            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Reference ID
            </TableCell>
            <TableCell
              sx={{ ...styles.tableCell, ...styles.tableCellHeader }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {tokens?.map((doc) => (
            <TokenView key={doc.id} doc={doc} />
          ))}
        </TableBody>
      </Table>
      {(tokens?.length ?? -1) === 0 && (
        <Typography variant="caption">
          No active Links.
          <br />
          <br />
        </Typography>
      )}
      <Typography variant="body2">
        Links are QR codes that are used to access models on a HoloLens 2. Links
        with long lifespans are shown here, typically generated by printing on
        paper. You can revoke unwanted or unnecessary access by removing the
        Link from the list above.
      </Typography>
    </AccountCollapse>
  );
}

function TokenView({
  doc,
}: {
  doc: firebase.firestore.QueryDocumentSnapshot<ModelTokenReference>;
}) {
  const token = doc.data();
  const { alert, confirm } = useAlerts();
  const [working, setWorking] = useState<boolean>(false);

  async function deleteModelToken() {
    if (
      !(await confirm(
        `Are you sure you want to remove this Link? If it has been printed, the printed Link will be defunct. This action can not be undone.`
      ))
    ) {
      return;
    }
    setWorking(true);

    try {
      // Remove device from the team
      await doc.ref.delete();
    } catch (err) {
      await alert(
        `Unable to remove this access token. Please contact support.`
      );
      setWorking(false);
    }
  }

  return (
    <TableRow>
      <TableCell
        id="name"
        size="small"
        sx={{ ...styles.tableCell, ...styles.nameCell }}
      >
        <strong>{token.model_name ?? `Model ${token.model_id}`}</strong>
      </TableCell>
      <TableCell id="link">
        <Tooltip title="Launch Model">
          <IconButton
            size="small"
            onClick={() => {
              window.open(`${config.appUri}/models/${token.model_id}`);
            }}
          >
            <Launch fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell
        size="small"
        id="access"
        sx={{ ...styles.tableCell, ...styles.tableCellSmall }}
      >
        {(token.accessed_at as firebase.firestore.Timestamp)
          ?.toDate()
          .toLocaleString() ?? "-"}
      </TableCell>
      <TableCell
        size="small"
        id="expiry"
        sx={{ ...styles.tableCell, ...styles.tableCellSmall }}
      >
        {new Date(token.token_expiry).toLocaleString()}
      </TableCell>

      <CopyableTableCell
        size="small"
        enabled
        data={token.token_ref}
        sx={{ ...styles.tableCell, ...styles.tableCellSmall }}
      >
        {token.token_ref}
      </CopyableTableCell>
      <TableCell size="small" id="delete" sx={styles.tableCell}>
        <IconButton size="small" disabled={working} onClick={deleteModelToken}>
          <Clear />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const styles = {
  table: {
    overflowX: "auto",
    maxWidth: "100%",
    width: "100%",
    marginBottom: 0.75,
  },
  nameCell: {
    maxWidth: 200,
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userPending: {
    color: "grey.50",
  },
  tableCellHeader: {},
  tableCell: {
    //minWidth: 96,
    whiteSpace: "nowrap",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  tableCellSmall: {
    fontSize: 12,
  },
} as const;
