import { useEffect } from "react";
import { useClaims } from "../Auth";
import AccountFrame from "../Accounts/AccountFrame";
import { Typography } from "@mui/material";
import { CreateTeamView } from "./CreateTeamView";
import { CreateUserView } from "./CreateUserView";

export function Admin() {
  const claims = useClaims();

  useEffect(() => {
    if (claims !== undefined && !claims?.admin) {
      window.location.assign("/");
    }
  }, [claims]);

  if (!claims?.admin) return null;

  return (
    <AccountFrame required={true} narrow>
      <AuthorizedAdminView />
    </AccountFrame>
  );
}

export default Admin;

function AuthorizedAdminView() {
  return (
    <>
      <Typography variant="h3" color="primary" gutterBottom sx={styles.title}>
        <strong>Admin Dashboard</strong>
      </Typography>
      <Typography variant="subtitle2" sx={styles.subtitle}>
        You have the power
      </Typography>
      <CreateTeamView />

      <CreateUserView />
    </>
  );
}

const styles = {
  title: {
    marginTop: 0.75,
  },
  subtitle: {
    marginBottom: 2.25,
  },
} as const;
