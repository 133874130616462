import { useClaims } from "Auth";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import CheckoutView from "./CheckoutView";
import ManageSubscriptionView from "./ManageSubscriptionView";
import { Team } from "Types";
import UsersView from "./UsersView";
import { useAlerts } from "Alerts";
import { TwinbuildLoaderIndeterminate } from "../components/Loaders";
import InvitationsView from "./InvitationsView";
import DevicesView from "./DevicesView";
import TokensView from "./TokensView";
import ModelsView from "./ModelsView";
import AccountPreamble from "./AccountPreamble";
import LeaveTeamView from "./LeaveTeamView";

const db = firebase.firestore();

export default function AccountPage() {
  const claims = useClaims();
  const [team, setTeam] = useState<Team | null | undefined>();

  const { alert } = useAlerts();

  useEffect(() => {
    console.info("Claims", claims);
    if (claims !== undefined) {
      if (claims?.team_id) {
        return db.doc(`teams/${claims.team_id}`).onSnapshot((doc) => {
          if (!doc.exists) {
            console.error("Team does not have matching database record.");
            alert("Data mismatch, team record missing.");
            return;
          }
          setTeam(doc.data() as Team); // Team found
          console.info("Team", doc.data());
        });
      } else {
        setTeam(null); // No team found
        console.info("No team found");
      }
    } else {
      setTeam(undefined); // Still waiting on claims
    }
  }, [claims, alert]);

  if (claims === undefined || team === undefined) {
    return <TwinbuildLoaderIndeterminate fullHeight fullWidth />;
  }

  return (
    <>
      {team !== undefined && <AccountPreamble team={team} />}
      {team && (
        <>
          <CheckoutView team={team} />
          <ManageSubscriptionView team={team} />
          <UsersView team={team} />
          <DevicesView team={team} />
          <TokensView team={team} />
          <ModelsView team={team} />
          <LeaveTeamView team={team} />
        </>
      )}

      {team === null && (
        <>
          <CheckoutView team={null} />
          <InvitationsView />
        </>
      )}
    </>
  );
}
