import AccountCollapse from "../Accounts/AccountCollapse";
import { TextField, Typography, Box, Chip } from "@mui/material";
import { WorkerButton } from "../components/WorkerButton";
import { useState } from "react";
import firebase from "firebase/compat/app";
import { useAlerts } from "../Alerts";
import { TeamScope } from "Types";
import { ensureRecentAuth } from "Auth";
import ms from "ms";

const db = firebase.firestore();

const functions = firebase.functions();

const INVITE_TEAM_MEMBER_FUNCTION = "inviteTeamMember";

type InviteTeamMemberRequest = {
  invitee_email: string;
  team_id?: string;
  team_scopes?: TeamScope[];
};

export function CreateUserView() {
  const alerts = useAlerts();

  const [userEmail, setUserEmail] = useState<string>("");
  const [teamNameOrId, setTeamNameOrId] = useState<string>("");
  const [scopes, setScopes] = useState<Record<TeamScope, boolean>>({
    owner: false,
    admin: false,
    finances: false,
    manager: false,
    user: true,
  });
  const [working, setWorking] = useState<boolean>(false);

  async function createUserInvitation() {
    if (!teamNameOrId || !userEmail) {
      await alerts.alert("Please fill out all fields.");
      return;
    }

    // Attempt to find team here. ..

    setWorking(true);

    let teamId: string | null = null;

    // See if we can find the team id in the database
    let team = await db.collection("teams").doc(teamNameOrId).get();
    if (team.exists) {
      teamId = teamNameOrId;
    } else {
      // See if we can find it by name
      const teams = await db
        .collection("teams")
        .where("nickname", "==", teamNameOrId)
        .get();

      if (teams.size === 1) {
        teamId = teams.docs[0].id;
      }
    }

    if (!teamId) {
      await alerts.alert("Unable to find team.");
      setWorking(false);
      return;
    }

    const selectedScopes: TeamScope[] = [];
    Object.keys(scopes).forEach((k) => {
      if (scopes[k as TeamScope]) {
        selectedScopes.push(k as TeamScope);
      }
    });

    const data: InviteTeamMemberRequest = {
      invitee_email: userEmail.toLowerCase().trim(),
      team_id: teamId,
      team_scopes: selectedScopes,
    };

    await ensureRecentAuth(ms("1h"), alerts);

    try {
      await functions.httpsCallable(INVITE_TEAM_MEMBER_FUNCTION)(data);
      await alerts.alert(
        `${userEmail} has been sent an invitation to join team ${teamId}`
      );
      setUserEmail("");
    } catch (err: any) {
      console.error("Error creating team", err);
      await alerts.alert(
        err.message ?? "Something went wrong while creating this team",
        "Oops"
      );
    } finally {
      setWorking(false);
    }
  }

  return (
    <AccountCollapse
      title="Invite User"
      description="Invite a user to join an existing team"
    >
      <Typography variant="body2" gutterBottom>
        This will create a user if required, invite them to an existing team and
        send them an invitation email.
      </Typography>

      <Box
        sx={{
          ...styles.fields,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Object.keys(scopes).map((k) =>
          k === "owner" ? null : (
            <Chip
              sx={{
                m: 0.25,
              }}
              key={k}
              label={k}
              variant={scopes[k as TeamScope] ? "filled" : "outlined"}
              color="primary"
              disabled={working}
              onClick={() => {
                setScopes((p) => {
                  const newScopes = { ...p };
                  newScopes[k as TeamScope] = !scopes[k as TeamScope];
                  return newScopes;
                });
              }}
            />
          )
        )}
      </Box>

      <Box sx={styles.fields}>
        <TextField
          sx={styles.field}
          size="small"
          label="User Email"
          placeholder="someone@something.net"
          variant="outlined"
          value={userEmail}
          onChange={(ev) => setUserEmail(ev.target.value)}
          disabled={working}
        />
        <TextField
          sx={styles.field}
          size="small"
          label="Exact Team Name or ID"
          placeholder="John Doe's Team"
          variant="outlined"
          value={teamNameOrId}
          onChange={(ev) => setTeamNameOrId(ev.target.value)}
          disabled={working}
        />
        <WorkerButton
          sx={styles.field}
          variant="contained"
          color="primary"
          working={working}
          onClick={createUserInvitation}
        >
          Send Invitation
        </WorkerButton>
      </Box>
    </AccountCollapse>
  );
}

const styles = {
  title: {
    marginTop: 0.75,
  },
  subtitle: {
    marginBottom: 2.25,
  },
  stepper: {
    backgroundColor: "inherit",
    margin: 2,
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 1,
  },
  field: {
    flexGrow: 10,
    flexBasis: 200,
    height: "auto",
    margin: 0.25,
  },
} as const;
