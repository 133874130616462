import { ReactComponent as Animated } from "static/images/wordmark.svg";
import React from "react";
import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

const draw = keyframes`
  0% {
    stroke-dashoffset: 1350;
  }
  75% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -1350;
  }
`;

const AnimatedLogoStyled = styled(Animated)({
  width: 128,
  height: 128,
  stroke: "#110066",
  strokeWidth: 10,
  fill: "#fff",
  strokeDasharray: 1350,
  animation: `${draw} 2s infinite linear`,
});

export function AnimatedLogo() {
  return <AnimatedLogoStyled />;
}

export function TwinbuildLoaderIndeterminate(props: LoaderProps) {
  return (
    <LoaderFrame {...props}>
      <AnimatedLogo />
    </LoaderFrame>
  );
}

type LoaderProps = {
  fullWidth?: boolean;
  fullHeight?: boolean;
};

function LoaderFrame({
  children,
  fullWidth,
  fullHeight,
}: {
  children: React.ReactNode;
} & LoaderProps) {
  return (
    <Box
      component="div"
      sx={{
        ...styles.loader,
        ...(fullWidth && styles.fullWidth),
        ...(fullHeight && styles.fullHeight),
      }}
    >
      {children}
    </Box>
  );
}

const styles = {
  loader: {
    pointerEvents: "none",
  },
  fullWidth: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  fullHeight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
} as const;
