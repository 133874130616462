import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  },
  spacing: 16,
  palette: {
    primary: {
      main: "#110066",
      light: "#90cbfb",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffd432",
    },
    text: {
      primary: "#111",
      secondary: "#D9D9D9",
    },
    background: {
      default: "#fafafa",
      paper: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize: "5rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      marginBottom: 64,
    },
    h2: {
      fontSize: "3rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      marginBottom: 48,
    },
    h3: {
      fontSize: "2.5rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      marginBottom: 32,
    },
    h4: {
      fontSize: "2rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      marginBottom: 32,
    },
    h5: {
      fontSize: "1.5rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      fontWeight: "bold",
      marginBottom: 32,
    },
    h6: {
      fontSize: "1.2rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      fontWeight: 100,
    },
    body2: {
      fontSize: "1rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      fontWeight: 100,
    },
    caption: {
      fontSize: "0.75rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      fontWeight: 100,
    },
    subtitle1: {
      fontSize: "0.75rem",
      fontFamily: ["Roboto, sans-serif"].join(","),
      fontWeight: 100,
    },
  },
});

export default responsiveFontSizes(theme);
