import { useAuth, useClaims } from "Auth";
import { useEffect, useMemo, useState } from "react";
import firebase from "firebase/compat/app";
import {
  Team,
  TeamMember,
  TeamScope,
  TeamScopeDescriptions,
  TeamScopes,
} from "Types";
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TableContainer,
} from "@mui/material";
import { Clear, Help } from "@mui/icons-material";
import { useAlerts } from "Alerts";
import AddUsersView from "./InviteUsersView";
import { AccountCollapse } from "./AccountCollapse";
const db = firebase.firestore();
const functions = firebase.functions();

const RESEND_INVITATION_FUNCTION = "inviteTeamMemberSendEmail";

export default function MembersView({ team }: { team: Team }) {
  const teamId = team.team_id;

  const claims = useClaims();

  const [members, setMembers] = useState<
    firebase.firestore.QueryDocumentSnapshot<TeamMember>[] | undefined
  >(undefined);

  useEffect(() => {
    return db
      .collection(`members`)
      .where("team_id", "==", teamId)
      .onSnapshot((snapshot) => {
        setMembers(
          snapshot.docs.map(
            (doc) => doc as firebase.firestore.QueryDocumentSnapshot<TeamMember>
          )
        );
      });
  }, [teamId]);

  const sorted = useMemo(() => {
    if (!members) return null;

    // Sort by email address first
    const sorted = [...members];
    sorted.sort((a, b) => (a.data().user_email > b.data().user_email ? 1 : -1));

    const owners: firebase.firestore.QueryDocumentSnapshot<TeamMember>[] = [];
    const users: firebase.firestore.QueryDocumentSnapshot<TeamMember>[] = [];
    const pending: firebase.firestore.QueryDocumentSnapshot<TeamMember>[] = [];

    sorted.forEach((doc) => {
      const member = doc.data();
      if (member.team_scopes.includes(TeamScopes.owner)) {
        owners.push(doc);
      } else if (member.is_pending) {
        pending.push(doc);
      } else {
        users.push(doc);
      }
    });

    return [...owners, ...users, ...pending];
  }, [members]);

  if (!claims?.isInATeam || !claims.isUsersManagerOrAbove) return null;

  return (
    <AccountCollapse
      title={"Manage Team"}
      description={"Invite team members and manage permissions"}
    >
      <TableContainer>
        <Table size="small" sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCell}>Role</TableCell>
              <TableCell
                sx={{ ...styles.tableCell, ...styles.tableCellHeader }}
              >
                <RoleDescription permissions={TeamScopeDescriptions.admin} />
              </TableCell>
              <TableCell
                sx={{ ...styles.tableCell, ...styles.tableCellHeader }}
              >
                <RoleDescription permissions={TeamScopeDescriptions.finances} />
              </TableCell>
              <TableCell
                sx={{ ...styles.tableCell, ...styles.tableCellHeader }}
              >
                <RoleDescription permissions={TeamScopeDescriptions.manager} />
              </TableCell>
              <TableCell
                sx={{ ...styles.tableCell, ...styles.tableCellHeader }}
              >
                <RoleDescription permissions={TeamScopeDescriptions.user} />
              </TableCell>
              <TableCell sx={styles.tableCellHeader} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted?.map((doc) => (
              <TeamMemberView key={doc.id} teamMember={doc} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddUsersView team={team} />
    </AccountCollapse>
  );
}

function RoleDescription({
  permissions,
}: {
  permissions: { name: string; permission: string };
}) {
  return (
    <Tooltip
      title={`The ${permissions.name.toLowerCase()} role allows users to ${
        permissions.permission
      }`}
    >
      <Box sx={styles.roleTooltipContainer}>
        {permissions.name}
        <Help sx={styles.roleTooltipIcon} />
      </Box>
    </Tooltip>
  );
}

function TeamMemberView({
  teamMember,
}: {
  teamMember: firebase.firestore.QueryDocumentSnapshot<TeamMember>;
}) {
  const member = teamMember.data();
  const scopes: TeamScope[] = member.team_scopes ?? [];
  const { alert, confirm } = useAlerts();
  const claims = useClaims();
  const auth = useAuth();
  const uid = auth?.uid;

  const permissions: TeamScope[] = claims?.team_scopes ?? [];
  const [hasResentInvitation, setHasResentInvitation] =
    useState<boolean>(false);

  async function toggleScope(scope: TeamScope) {
    const newScopes = [...scopes];
    const ind = scopes.indexOf(scope);
    if (ind >= 0) {
      newScopes.splice(ind, 1);
      // Removing scope
      if (
        !(await confirm(
          `Are you sure you want to remove permission for ${member.user_email} to ${TeamScopeDescriptions[scope].permission}?`
        ))
      ) {
        return;
      }
    } else {
      newScopes.push(scope);
      // Adding scope
      if (
        !(await confirm(
          `Are you sure you want to grant ${member.user_email} permission to ${TeamScopeDescriptions[scope].permission}?`
        ))
      ) {
        return;
      }
    }
    const scopeUpdate: Partial<TeamMember> = {
      team_scopes: newScopes,
    };

    try {
      await teamMember.ref.update(scopeUpdate);
    } catch (err) {
      console.error("Unable to update user scope", err);
      await alert("Unable to update scope. Please contact support");
    }
  }

  async function deleteMember() {
    if (member.user_id != null && member.user_id === uid) {
      if (
        !(await confirm(
          `Are you sure you want to leave this team? You will no longer be able to access any team resources.`
        ))
      ) {
        return;
      }
    } else if (member.is_pending) {
      if (
        !(await confirm(
          `Are you sure you want to revoke this invitation? User ${member.user_email} will no longer be able to join your team.`
        ))
      ) {
        return;
      }
    } else {
      if (
        !(await confirm(
          `Are you sure you want to remove this team member? User ${member.user_email} will no longer be able to access team resources.`
        ))
      ) {
        return;
      }
    }
    try {
      await teamMember.ref.delete();
    } catch (err) {
      await alert(
        `Unable to remove team member ${member.user_email}. Please contact support.`
      );
    }
  }

  type SendTeamInvitationRequest = {
    user_email: string;
  };

  async function resendInvitation() {
    setHasResentInvitation(true);

    const data: SendTeamInvitationRequest = {
      user_email: member.user_email,
    };

    try {
      await functions.httpsCallable(RESEND_INVITATION_FUNCTION)(data);
    } catch (err) {
      await alert(`Unable to send invitation to ${member.user_email}`);
      setHasResentInvitation(false);
    }
  }

  const memberOwner = scopes.includes(TeamScopes.owner);
  const memberAdmin = scopes.includes(TeamScopes.admin);
  const memberManager = scopes.includes(TeamScopes.manager);

  const currentOwner = permissions.includes(TeamScopes.owner);
  const currentAdmin = permissions.includes(TeamScopes.admin);
  const currentManager = permissions.includes(TeamScopes.manager);

  const isCurrentUser = member.user_id === uid;

  return (
    <TableRow>
      <TableCell sx={{ ...styles.tableCell, ...styles.nameCell }}>
        <Typography
          sx={{
            ...styles.nameTextContainer,
            ...(memberOwner && styles.userOwner),
            ...(member.is_pending && styles.userPending),
          }}
        >
          {member.user_email}
        </Typography>
        {member.is_pending && (
          <Typography variant="caption" sx={styles.userPending}>
            Invitation Pending.{" "}
            {(currentOwner || currentAdmin || currentManager) && (
              <Box
                sx={hasResentInvitation ? styles.linkInactive : styles.link}
                onClick={resendInvitation}
              >
                Resend.
              </Box>
            )}
          </Typography>
        )}
        <Typography variant="caption" sx={styles.userPending}>
          {memberOwner && `Team Owner ${isCurrentUser ? "(You)" : ""}`}
          {!memberOwner && isCurrentUser && "You"}
        </Typography>
      </TableCell>
      <TableCell id="admin" sx={styles.tableCell}>
        <ScopeCheckBox
          state={memberOwner || memberAdmin}
          onChange={() => toggleScope(TeamScopes.admin)}
          disabled={
            isCurrentUser || memberOwner
              ? true
              : !(currentAdmin || currentOwner)
          }
        />
      </TableCell>
      <TableCell id="finances" sx={styles.tableCell}>
        <ScopeCheckBox
          state={
            scopes.includes(TeamScopes.finances) || memberAdmin || memberOwner
          }
          onChange={() => toggleScope(TeamScopes.finances)}
          disabled={
            isCurrentUser || memberOwner || memberAdmin
              ? true
              : !(currentAdmin || currentOwner)
          }
        />
      </TableCell>
      <TableCell id="users" sx={styles.tableCell}>
        <ScopeCheckBox
          state={
            scopes.includes(TeamScopes.manager) || memberAdmin || memberOwner
          }
          onChange={() => toggleScope(TeamScopes.manager)}
          disabled={
            isCurrentUser || memberOwner || memberAdmin
              ? true
              : !(currentAdmin || currentOwner || currentManager)
          }
        />
      </TableCell>
      <TableCell id="models" sx={styles.tableCell}>
        <ScopeCheckBox
          state={
            scopes.includes(TeamScopes.user) ||
            memberAdmin ||
            memberOwner ||
            memberManager
          }
          onChange={() => toggleScope(TeamScopes.user)}
          disabled={
            isCurrentUser || memberOwner || memberManager || memberAdmin
              ? true
              : !(currentAdmin || currentOwner || currentManager)
          }
        />
      </TableCell>
      <TableCell id="delete">
        <Box sx={styles.tableCellInner}>
          <IconButton
            size="small"
            disabled={
              memberOwner || (memberAdmin && !(currentAdmin || currentOwner))
            }
            onClick={deleteMember}
          >
            <Clear />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function ScopeCheckBox({
  state,
  onChange,
  disabled,
}: {
  state: boolean;
  onChange: (state: boolean) => any;
  disabled?: boolean;
}) {
  return (
    <Box sx={styles.tableCellInner}>
      <Checkbox
        color="primary"
        disabled={disabled}
        checked={state}
        onChange={(ev) => onChange(ev.currentTarget.checked)}
      />
    </Box>
  );
}

const styles = {
  table: {
    overflowX: "auto",
    maxWidth: "100%",
    width: "100%",
  },
  nameCell: {
    width: "100%",
  },
  nameTextContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 280,
  },
  userOwner: {
    fontWeight: "bold",
  },
  userPending: {
    color: "grey.500",
  },
  tableCellHeader: { textAlign: "center" },
  tableCell: {
    minWidth: {
      sm: 96,
      md: 122,
    },
  },
  link: {
    color: "primary.main",
    cursor: "pointer",
  },
  linkInactive: {
    color: "inherit",
  },
  tableCellInner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  roleTooltipContainer: {
    display: "flex",
    flexDirection: {
      sm: "column",
      md: "row",
    },
    alignItems: "center",
    justifyContent: "center",
  },
  roleTooltipIcon: {
    color: "grey.400",
    width: 16,
    height: 16,
    marginLeft: 0.5,
  },
} as const;
