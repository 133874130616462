import React from "react";
import { Route, Routes } from "react-router-dom";
import AccountFrame from "./AccountFrame";
import RedirectView from "./RedirectView";
import AccountView from "./AccountView";
import Footer from "../Footer";
export default function AccountRoutes() {
  return (
    <>
      <AccountFrame required={true} narrow>
        <Routes>
          <Route path="/redirect" element={<RedirectView />} />
          <Route path="/" element={<AccountView />} />
        </Routes>
      </AccountFrame>
      <Footer />
    </>
  );
}
