import { useTheme, Typography, Grid, Link } from "@mui/material";
import { useAuth } from "./Auth";
import queryString from "query-string";
import { ReactComponent as Logo } from "static/images/wordmark.svg";

const FooterBar = () => {
  const auth = useAuth();
  const theme = useTheme();

  const contact: any = {
    prompt: "How can we help?",
    email: auth?.email,
    name: auth?.displayName,
  };

  return (
    <Grid
      id="test123"
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "200px",
        backgroundColor: "#D2D2D2",
      }}
    >
      <Grid
        container
        sx={{ maxWidth: 960, display: "flex", pr: 1.5, pl: 1.5, pt: 2, pb: 2 }}
      >
        <Grid item sm={6} xs={12}>
          <Link href="https://twinbuild.com">
            <Logo
              style={{
                maxWidth: 64,
                maxHeight: 64,
                fill: theme.palette.primary.main,
              }}
            />
          </Link>
          <Typography variant="body2" color="primary">
            © 2021 Fologram Pty Ltd
          </Typography>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography
            variant="body1"
            color="primary"
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            <strong>App</strong>
          </Typography>
          <Link
            href="https://twinbuild.com/download"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Download
            </Typography>
          </Link>
          <Link
            href="https://account.twinbuild.com"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Account
            </Typography>
          </Link>
          <Link
            href="https://docs.twinbuild.com"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer" }}
            >
              Docs
            </Typography>
          </Link>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography
            variant="body1"
            color="primary"
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            <strong>Twinbuild</strong>
          </Typography>
          <Link
            href="https://twinbuild.com/#advantages"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Advantages
            </Typography>
          </Link>
          <Link
            href="https://twinbuild.com/#applications"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Applications
            </Typography>
          </Link>
          <Link
            href="https://twinbuild.com/#demo"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Demo
            </Typography>
          </Link>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography
            variant="body1"
            color="primary"
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            <strong>Company</strong>
          </Typography>

          <Link
            href={`https://twinbuild.com/contact?${queryString.stringify(
              contact
            )}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginBottom: 8 }}
            >
              Contact
            </Typography>
          </Link>
          <Link
            href="https://twinbuild.com/terms"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Terms of Use
            </Typography>
          </Link>
          <Link
            href="https://twinbuild.com/privacy"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ cursor: "pointer", marginBottom: 8 }}
            >
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterBar;
