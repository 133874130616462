import { useClaims } from "Auth";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Device, Team } from "Types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CopyableTableCell } from "../components/TableCells";
import AccountCollapse from "./AccountCollapse";

const db = firebase.firestore();

export default function DevicesView({ team }: { team: Team }) {
  const teamId = team.team_id;
  const claims = useClaims();
  const canEditDevices = claims?.isInATeam && claims.isUsersManagerOrAbove;

  const [devices, setDevices] = useState<
    firebase.firestore.QueryDocumentSnapshot<Device>[] | undefined
  >(undefined);

  useEffect(() => {
    if (!canEditDevices) return;

    return db
      .collection(`devices`)
      .where("team_id", "==", teamId)
      .onSnapshot((snapshot) => {
        setDevices(
          snapshot.docs.map(
            (doc) => doc as firebase.firestore.QueryDocumentSnapshot<Device>
          )
        );
      });
  }, [teamId, canEditDevices]);

  if (!canEditDevices) return null;

  return (
    <AccountCollapse
      title="Manage Seats and Devices"
      description="View seats assigned to HoloLens 2 headsets"
    >
      <Table size="small" sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Name
            </TableCell>
            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Last Contact
            </TableCell>
            <TableCell sx={{ ...styles.tableCell, ...styles.tableCellHeader }}>
              Device ID
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices?.map((doc) => (
            <DeviceView key={doc.id} doc={doc} />
          ))}
        </TableBody>
      </Table>
      {(devices?.length ?? -1) === 0 && (
        <Typography variant="caption" gutterBottom>
          No devices attached. <br />
          <br />
        </Typography>
      )}
      <Typography variant="body2">
        Devices are automatically attached to your account when viewing team
        models. To remove a device from your account and free up a license seat,
        please contact support.{" "}
      </Typography>
    </AccountCollapse>
  );
}

function DeviceView({
  doc,
}: {
  doc: firebase.firestore.QueryDocumentSnapshot<Device>;
}) {
  const device = doc.data();

  return (
    <TableRow>
      <TableCell id="name" sx={{ ...styles.tableCell, ...styles.nameCell }}>
        {device.device_name}
      </TableCell>
      <TableCell id="contact" sx={styles.tableCell}>
        {(device.last_contact as firebase.firestore.Timestamp)
          .toDate()
          .toLocaleString()}
      </TableCell>

      <CopyableTableCell enabled data={device.device_id}>
        {device.device_id}
      </CopyableTableCell>
    </TableRow>
  );
}

const styles = {
  table: {
    overflowX: "auto",
    maxWidth: "100%",
    width: "100%",
    marginBottom: 0.75,
  },
  nameCell: {
    width: "100%",
    fontWeight: "bold",
  },
  userPending: {
    color: "grey.500",
  },
  tableCellHeader: {},
  tableCell: {
    minWidth: 96,
    whiteSpace: "nowrap",
  },
  link: {
    color: "primary.main",
    cursor: "pointer",
  },
  linkInactive: {
    color: "inherit",
  },
} as const;
