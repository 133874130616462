import { loadStripe, Stripe } from "@stripe/stripe-js";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import config from "./config";

const stripePromise = loadStripe(config.stripe.public_key);

const StripeContext = createContext<Stripe | undefined>(undefined);

export const useStripe = () => useContext(StripeContext);

export default function StripeProvider({ children }: { children: ReactNode }) {
  const [stripe, setStripe] = useState<Stripe>();

  useEffect(() => {
    let cancelled = false;
    (async () => {
      const stripe = await stripePromise;
      if (!cancelled) {
        setStripe(stripe ?? undefined);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>
  );
}
