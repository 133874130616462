import { useClaims, useAuth } from "Auth";
import React from "react";
import firebase from "firebase/compat/app";
import { Team } from "Types";
import { Button } from "@mui/material";
import { useAlerts } from "Alerts";
import { useNavigate } from "react-router-dom";
import { AccountHeader } from "./AccountCollapse";
const db = firebase.firestore();

export default function LeaveTeamView({ team }: { team: Team }) {
  const claims = useClaims();
  const auth = useAuth();
  const navigate = useNavigate();

  const { confirm } = useAlerts();

  if (!team) return null;
  if (claims?.isOwner) return null;

  async function leaveTeam() {
    if (
      await confirm(
        `Are you sure you want to leave this team? You will no longer be able to access any team resources. You'll need to be re-invited by a manager or administrator to rejoin.`,
        "Leave Team"
      )
    ) {
      await db.doc(`members/${auth!.email!.toLowerCase()}`).delete();
      navigate(`/redirect?url=${encodeURIComponent("/")}`);
    }
  }

  return (
    <>
      <AccountHeader
        title={""}
        description={""}
        button={
          <Button sx={styles.button} onClick={leaveTeam}>
            Leave Team
          </Button>
        }
        disableDivider
      />
    </>
  );
}

const styles = {
  button: {
    color: "grey.300",
  },
} as const;
