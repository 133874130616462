import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./Auth";
import { AuthProvider, RouteAnalytics } from "./Auth";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./Theme";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { AlertsProvider } from "./Alerts";
import Logger from "Logger";

ReactDOM.render(
  <React.StrictMode>
    <Logger>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <AlertsProvider>
            <AuthProvider>
              <Router>
                <RouteAnalytics />
                <App />
              </Router>
            </AuthProvider>
          </AlertsProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Logger>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
