export type TeamScope = "user" | "manager" | "finances" | "admin" | "owner";
export const ALL_SCOPES: TeamScope[] = [
  "user",
  "manager",
  "finances",
  "admin",
  "owner",
];
export const TeamScopes: Record<TeamScope, TeamScope> = {
  admin: "admin",
  user: "user",
  manager: "manager",
  finances: "finances",
  owner: "owner",
};

interface TeamScopeDescription {
  name: string;
  permission: string;
}

export const TeamScopeDescriptions: Record<TeamScope, TeamScopeDescription> = {
  admin: {
    name: "Admin",
    permission: "manage all team related information",
  },
  user: {
    name: "User",
    permission: "view, edit, add or remove models",
  },
  manager: {
    name: "Manager",
    permission: "add and manage users",
  },
  finances: {
    name: "Finance",
    permission: "manage plan, seat and payment information",
  },
  owner: {
    name: "Owner",
    permission: "manage all team related information",
  },
};
