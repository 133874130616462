import { Box, TableCell, TableCellProps, Tooltip } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { Edit, FileCopy } from "@mui/icons-material";

export function ClickableTableCell({
  children,
  onClick,
  enabled,
}: {
  children: ReactNode;
  onClick: () => any;
  enabled: boolean;
}) {
  return (
    <TableCell
      sx={{ ...styles.tableCell, ...(enabled && styles.link) }}
      onClick={() => {
        if (enabled) {
          onClick();
        }
      }}
    >
      <Box sx={styles.cellContainer}>
        {children}
        {enabled && <Edit sx={styles.editIcon} />}
      </Box>
    </TableCell>
  );
}

export function CopyableTableCell({
  children,
  data,
  enabled,
  ...other
}: {
  children: ReactNode;
  enabled: boolean;
  data: string;
} & TableCellProps) {
  const [tooltip, setTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (tooltip) {
      const to = setTimeout(() => {
        setTooltip(false);
      }, 1000);

      return () => clearTimeout(to);
    }
  }, [tooltip]);

  const canUseClipboard = enabled && navigator.clipboard?.writeText != null;

  return (
    <TableCell
      sx={{ ...styles.tableCell, ...(canUseClipboard && styles.link) }}
      onClick={() => {
        if (canUseClipboard) {
          navigator.clipboard.writeText(data);
          setTooltip(true);
        }
      }}
      {...other}
    >
      <Box sx={styles.cellContainer}>
        <Tooltip title="Copied" open={tooltip}>
          <Box sx={styles.contentContainer}>
            <Box sx={styles.text}>{children}</Box>
            {canUseClipboard && (
              <FileCopy fontSize="small" sx={styles.editIcon} />
            )}
          </Box>
        </Tooltip>
      </Box>
    </TableCell>
  );
}

const styles = {
  tableCell: { width: "100%" },
  cellContainer: {
    display: "flex",
    alignItems: "center",
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  text: {
    width: "100%",
  },
  editIcon: {
    marginLeft: 0.5,
    color: "grey.500",
    cursor: "pointer",
  },
  link: {
    cursor: "pointer",
  },
} as const;
