import { ensureRecentAuth, useClaims } from "Auth";
import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { Team } from "Types";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useAlerts } from "Alerts";
import WorkerButton from "components/WorkerButton";
import { Send } from "@mui/icons-material";
import ms from "ms";

const INVITE_TEAM_MEMBER_FUNCTION = "inviteTeamMember";

type InviteTeamMemberRequest = {
  invitee_email: string;
};

export default function AddUsersView({ team }: { team: Team }) {
  const [email, setEmail] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>();
  const claims = useClaims();

  const alerts = useAlerts();

  if (!claims?.isInATeam || !claims.isUsersManagerOrAbove) return null;

  async function sendInvite() {
    setWorking(true);
    if (!(await ensureRecentAuth(ms("1h"), alerts))) {
      setWorking(false);
      return;
    }
    const addr = email.trim().toLowerCase();
    const email_regex = /^[^@\s]+@[^@\s.]+.[^@\s]+$/g;
    if (!email_regex.test(addr)) {
      setEmailErrorMsg("Please enter a valid email address.");
      setWorking(false);
      return;
    }

    try {
      const request: InviteTeamMemberRequest = {
        invitee_email: addr,
      };

      console.info("Calling invitation function");
      await firebase.functions().httpsCallable(INVITE_TEAM_MEMBER_FUNCTION)(
        request
      );
      setEmail("");
    } catch (err: any) {
      await alerts.alert(err.message ?? "Unable to send invitation");
    }
    setWorking(false);
  }

  return (
    <>
      <Typography
        component="div"
        variant="caption"
        sx={{ m: 0.5, mt: 1, mb: 0 }}
      >
        Invite a user to join your team:
      </Typography>
      <Box sx={styles.controlsContainer}>
        <Box sx={styles.textFieldContainer}>
          <TextField
            error={emailErrorMsg != null}
            size="small"
            variant="outlined"
            label="Email Address"
            disabled={working}
            value={email}
            onChange={(ev) => {
              setEmail(ev.target.value);
              setEmailErrorMsg(undefined);
            }}
            sx={styles.textField}
            onKeyDown={(ev) => {
              if (["Enter", "NumpadEnter"].includes(ev.key)) {
                sendInvite();
              }
            }}
          />
        </Box>
        <FormControl size="small">
          <WorkerButton
            variant="text"
            color="primary"
            disabled={working || emailErrorMsg != null}
            working={working}
            type="submit"
            sx={styles.button}
            onClick={sendInvite}
            endIcon={<Send />}
          >
            {working ? "Sending..." : "Send invitation"}
          </WorkerButton>
        </FormControl>
      </Box>
      {emailErrorMsg && <FormHelperText error>{emailErrorMsg}</FormHelperText>}
    </>
  );
}

const styles = {
  controlsContainer: {
    width: "100%",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    display: "flex",
    justifyContent: "center",
    marginTop: 0.5,
  },
  textField: {
    width: "100%",
  },
  textFieldContainer: {
    flexGrow: 1,
    display: "flex",
    margin: 0.25,
  },
  button: { height: "100%", paddingLeft: 1.5, paddingRight: 1.5 },
} as const;
