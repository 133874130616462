import { ReactNode, useEffect, useState } from "react";
import { Button, Collapse, Divider, Typography, Box } from "@mui/material";
export function AccountCollapse({
  title,
  description,
  expand,
  children,
  expandText = "Edit",
  collapseText = "Hide",
}: {
  title: ReactNode;
  description: ReactNode;
  expand?: boolean;
  children: ReactNode;
  expandText?: string;
  collapseText?: string;
}) {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    if (expand === true) {
      setCollapsed(false);
    }
  }, [expand]);

  return (
    <AccountHeader
      title={title}
      description={description}
      button={
        <Button
          color="primary"
          variant="text"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? expandText : collapseText}
        </Button>
      }
    >
      <Collapse in={!collapsed}>{children}</Collapse>
    </AccountHeader>
  );
}

export default AccountCollapse;

export function AccountHeader({
  title,
  description,
  children,
  button,
  disableDivider,
}: {
  title: ReactNode;
  description: ReactNode;
  children?: ReactNode;
  button?: ReactNode;
  disableDivider?: boolean;
}) {
  return (
    <>
      {!disableDivider && <Divider sx={styles.divider} />}
      <Box component="div" sx={styles.header}>
        <Box component="span" sx={styles.titleContainer}>
          <Typography variant="h6" color="primary" sx={styles.title}>
            {title}
          </Typography>
          {button}
        </Box>
        <Typography variant="caption" sx={styles.description} gutterBottom>
          {description}
        </Typography>
      </Box>
      <Box component="div" sx={styles.container}>
        {children}
      </Box>
    </>
  );
}

const styles = {
  container: {
    marginBottom: 3,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowX: "auto",
  },
  divider: {
    width: "100%",
    marginBottom: 3,
  },
  header: {
    width: "100%",
    flexDirection: "column",
    display: "flex",
  },
  description: {
    maxWidth: 400,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 0.5,
  },
  title: {
    flexGrow: 1,
  },
  button: {
    minWidth: 100,
  },
} as const;
