import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { Team } from "Types";
import { useClaims, ensureRecentAuth } from "Auth";
import { useAlerts } from "Alerts";
import WorkerButton from "components/WorkerButton";
import { AccountHeader } from "./AccountCollapse";
import { Launch } from "@mui/icons-material";
import ms from "ms";

const STRIPE_PORTAL_FUNCTION = "stripeBillingPortalSession";

type CustomerPortalRequest = {
  return_url: string;
};

type CustomerPortalResponse = {
  url: string;
};

export default function ManageSubscriptionView({ team }: { team: Team }) {
  const claims = useClaims();
  const [working, setWorking] = useState<boolean>(false);
  const alerts = useAlerts();

  if (!claims?.isInATeam || !claims.isFinanceManagerOrAbove) return null;
  if ((team.total_subscription_quantity ?? 0) <= 0) return null; // No subscription to manage

  async function launchPortal() {
    setWorking(true);
    if (!(await ensureRecentAuth(ms("1h"), alerts))) {
      setWorking(false);
      return;
    }

    const request: CustomerPortalRequest = {
      return_url: `${window.location.origin}/redirect?url=${encodeURIComponent(
        `${window.location.pathname}`
      )}`,
    };

    console.info("Request", request);

    try {
      const response = await firebase
        .functions()
        .httpsCallable(STRIPE_PORTAL_FUNCTION)(request);

      if (!response.data) throw new Error("Unable to open billing portal");

      const session = (response.data as CustomerPortalResponse).url;
      window.location.href = session;
    } catch (err) {
      await alerts.alert("Unable to open billing portal");
      console.error(err);
      setWorking(false);
    }
  }

  return (
    <>
      <AccountHeader
        title={`Manage Subscription (${team.total_subscription_quantity} seats)`}
        description="Edit payment information, plan, and subscription quantity"
        button={
          <WorkerButton
            color="primary"
            working={working}
            onClick={launchPortal}
            endIcon={<Launch />}
          >
            Edit
          </WorkerButton>
        }
      />
    </>
  );
}
