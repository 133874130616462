import { useClaims } from "Auth";
import React from "react";
import { Team } from "Types";
import config from "config";
import { Button } from "@mui/material";
import { AccountHeader } from "./AccountCollapse";
import { Launch } from "@mui/icons-material";

export default function ModelsView({ team }: { team: Team }) {
  const claims = useClaims();
  const canViewModels = claims?.isInATeam && claims.isModelsManagerOrAbove;

  if (!canViewModels) return null;

  return (
    <AccountHeader
      title="Published Models"
      description="View and edit models published by your team"
      button={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.open(config.appUri, "_blank");
          }}
          endIcon={<Launch />}
        >
          Launch
        </Button>
      }
    ></AccountHeader>
  );
}
