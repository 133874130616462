import { EmailVerificationDialog, useAuth, useClaims } from "../Auth";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { TeamMember } from "../Types";
import { useAlerts } from "Alerts";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import WorkerButton from "components/WorkerButton";

const db = firebase.firestore();

const ACCEPT_INVITATION_FUNCTION = "acceptTeamInvitation";

export default function InvitationsView() {
  const auth = useAuth();

  const emailVerified = auth?.emailVerified ?? false;
  const userEmail = auth?.email;
  const { alert, confirm } = useAlerts();
  const claims = useClaims();
  const navigate = useNavigate();
  const [working, setWorking] = useState<boolean>(false);

  const [member, setMember] = useState<
    firebase.firestore.DocumentSnapshot<TeamMember> | null | undefined
  >();

  const invite = useMemo(() => {
    if (member) {
      return member.data();
    }
    return null;
  }, [member]);

  useEffect(() => {
    if (claims === undefined) {
      return; // Waiting for claims
    } else if (claims?.team_id) {
      console.info("User is already in a team.");
      navigate("/");
    }

    if (userEmail) {
      return db.doc(`members/${userEmail.toLowerCase()}`).onSnapshot((doc) => {
        if (doc.exists) {
          const member = doc.data() as TeamMember;
          if (member.is_pending) {
            setMember(doc as firebase.firestore.DocumentSnapshot<TeamMember>);
          } else {
            setMember(null);
          }
        } else {
          setMember(null);
        }
      });
    }
  }, [userEmail, claims, navigate]);

  async function acceptInvitation() {
    if (!member) return;
    const invite = member.data();
    if (!invite) return;

    try {
      setWorking(true);
      // Ensure ID token is refreshed
      await auth!.getIdToken(true);
      await firebase.functions().httpsCallable(ACCEPT_INVITATION_FUNCTION)();
      navigate(`/redirect?url=${encodeURIComponent("/")}`);
      alert(`You have joined ${invite.invited_by}'s team`, "Success");
    } catch (err: any) {
      console.error("Unable to handle invitation", err);
      alert(
        err.message ??
          "Something went wrong with this invitation. Please request a new one from the team manager."
      );
      setWorking(false);
      return;
    }
  }

  async function declineInvitation() {
    if (!member) return;
    const invite = member.data();
    if (!invite) return;

    try {
      if (
        !(await confirm(
          "Are you sure you want to decline this invitation? You'll need to request a new one to join the team."
        ))
      ) {
        return;
      }
      await member.ref.delete();
      await alert("This invitation has been declined.");
      navigate(`/`);
    } catch (err: any) {
      console.error("Unable to handle invitation", err);
      alert(
        err.message ??
          "Something went wrong with this invitation. Please contact support."
      );
      return;
    }
  }

  if (invite == null) return null;

  if (!emailVerified && auth?.providerData[0]?.providerId !== "microsoft.com")
    return (
      <EmailVerificationDialog
        open={!emailVerified}
        message="Your email address must be verified to join a team."
      />
    );

  return (
    <Dialog open={true}>
      <DialogTitle>Team Invitation</DialogTitle>
      <DialogContent>
        {invite.invited_by === userEmail ? (
          <Typography variant="body1" gutterBottom>
            You've been invited to to join the team "{invite.team_name}".
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom>
            {invite.invited_by} has invited you to join the team "
            {invite.team_name}".
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={declineInvitation} disabled={working}>
          Decline
        </Button>
        <WorkerButton
          variant="contained"
          color="primary"
          onClick={acceptInvitation}
          working={working}
          sx={styles.acceptButton}
        >
          Accept
        </WorkerButton>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  acceptButton: {
    marginRight: 0.5,
  },
} as const;
