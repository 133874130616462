import configs from "config.json";
import queryString from "query-string";

interface FirebaseConfig {
  projectId: string;
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface Config {
  /**
   * The name of the app, i.e. Canary
   */
  name: string;
  /**
   * App identifier used for deep linking, i.e. twinbuild
   */
  protocol: string;
  /**
   * The uri used for the twinbuild web app
   */
  appUri: string;
  /**
   * Firebase initialization options
   */
  firebase: FirebaseConfig;
  /**
   * True if running on localhost
   */
  runningLocally: boolean;
  /**
   * If true, logs will appear in the console
   */
  logToConsole: boolean;
  /**
   * Stripe config
   */
  stripe: {
    public_key: string;
  };
}

const stage = process.env.REACT_APP_STAGE;

const config: Config = {
  ...(stage === "production" ? configs.production : configs.development),
  runningLocally: window.location.hostname === "localhost",
};

export const isDevelopment = stage !== "production";

export const isHoloLens =
  // Check if the launch hash contains platform=hololens
  queryString.parseUrl(window.location.href).query.platform === "hololens" ||
  // Dirty check, edge running on ARM64.
  (window.navigator.userAgent.indexOf("Windows NT 10.0; ARM64") > -1 &&
    window.navigator.userAgent.indexOf("Edg") > -1);

export default config;
