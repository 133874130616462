import * as qs from "query-string";
import { useLocation } from "react-router-dom";

export default function useQueryString<T>() {
  const { search } = useLocation();

  return qs.parse(search, {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: "comma",
  }) as any as T;
}

export function useHash<T>() {
  const { hash } = useLocation();

  return qs.parse(hash, {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: "comma",
  }) as any as T;
}
