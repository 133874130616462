import { Typography, Box, Link } from "@mui/material";
import { useAlerts } from "Alerts";
import useQueryString from "hooks/useQueryString";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatedLogo } from "../components/Loaders";

export default function LoaderIndeterminate() {
  const { url } = useQueryString<{ url?: string }>();
  const { alert } = useAlerts();
  const navigate = useNavigate();
  const { title } = useQueryString<{ title?: string }>();

  const [remaining, setRemaining] = useState<number>(0);

  useEffect(() => {
    console.info("Redirecting", url);

    let seconds = 5;
    setRemaining(seconds);

    const interval = setInterval(() => {
      seconds--;
      setRemaining(seconds);
      if (seconds === 0) {
        navigate(url ?? "/");
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [url, alert, navigate]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerInner}>
        <Box sx={styles.block}>
          <Typography variant="h6">{title ?? "Redirecting"}</Typography>
        </Box>
        <AnimatedLogo />
        <Box sx={styles.block}>
          <Typography>You'll be redirected in {remaining} seconds.</Typography>
          <Typography variant="caption">
            Not working?{" "}
            <Link href={url} sx={styles.a}>
              Click here.
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  a: {
    textDecoration: "none",
  },
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  containerInner: {
    minWidth: 200,
    maxWidth: 400,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  block: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
} as const;
