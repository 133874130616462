import React from "react";
import { Routes, Route } from "react-router-dom";
import AccountRoutes from "./Accounts/AccountRoutes";
import StripeProvider from "./StripeProvider";
import Admin from "./Admin/Admin";

function App() {
  return (
    <>
      <StripeProvider>
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<AccountRoutes />} />
        </Routes>
      </StripeProvider>
    </>
  );
}

export default App;
