import AccountCollapse from "../Accounts/AccountCollapse";
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { WorkerButton } from "../components/WorkerButton";
import { useState } from "react";
import firebase from "firebase/compat/app";
import { useAlerts } from "../Alerts";
import { Launch } from "@mui/icons-material";
import { isDevelopment } from "../config";

const functions = firebase.functions();

const CREATE_TEAM_FUNCTION = "adminCreateTeam";

interface CreateTeamRequest {
  user_email: string;
  user_name: string;
  team_name: string;
}

interface CreateTeamResponse {
  customer_id: string;
  user_id?: string;
  team_id: string;
}

export function CreateTeamView() {
  const alerts = useAlerts();

  const [userEmail, setUserEmail] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [teamName, setTeamName] = useState<string>("");

  const [working, setWorking] = useState<boolean>(false);

  const [response, setResponse] = useState<CreateTeamResponse>();

  async function createTeam() {
    if (!userName || !teamName || !userEmail) {
      await alerts.alert("Please fill out all fields.");
      return;
    }

    setWorking(true);
    const data: CreateTeamRequest = {
      user_email: userEmail.toLowerCase().trim(),
      user_name: userName,
      team_name: teamName,
    };
    try {
      const res: CreateTeamResponse = (
        await functions.httpsCallable(CREATE_TEAM_FUNCTION)(data)
      ).data;
      setResponse(res);
    } catch (err: any) {
      console.error("Error creating team", err);
      await alerts.alert(
        err.message ?? "Something went wrong while creating this team",
        "Oops"
      );
    } finally {
      setWorking(false);
    }
  }

  const activeStep = response == null ? 0 : 1;

  return (
    <AccountCollapse
      title="Create Team"
      description="Create user and stripe account"
    >
      <Stepper sx={styles.stepper} activeStep={activeStep}>
        <Step>
          <StepLabel>Create User & Team</StepLabel>
        </Step>

        <Step>
          <StepLabel>Add Subscriptions</StepLabel>
        </Step>
      </Stepper>
      {!response && (
        <>
          <Typography variant="body2" gutterBottom>
            This will create a user, team, and Stripe account. If the user
            already exists, a team and Stripe account will be created for the
            existing user.
          </Typography>

          <Box sx={styles.fields}>
            <TextField
              sx={styles.field}
              size="small"
              label="User Email"
              placeholder="someone@something.net"
              variant="outlined"
              value={userEmail}
              onChange={(ev) => setUserEmail(ev.target.value)}
            />

            <TextField
              sx={styles.field}
              size="small"
              label="User Name"
              placeholder="John Doe"
              variant="outlined"
              value={userName}
              onChange={(ev) => setUserName(ev.target.value)}
            />
            <TextField
              sx={styles.field}
              size="small"
              label="Team Name"
              placeholder="John Doe's Team"
              variant="outlined"
              value={teamName}
              onChange={(ev) => setTeamName(ev.target.value)}
            />
            <WorkerButton
              sx={styles.field}
              variant="contained"
              color="primary"
              working={working}
              onClick={createTeam}
            >
              Next
            </WorkerButton>
          </Box>
        </>
      )}
      {response && (
        <>
          <Typography variant="body2" gutterBottom>
            We've created a team for {userName} with details below. You can now
            add a subscription or trial to this customer via the stripe
            dashboard.
            <br />
            <br />
            Please let them know they can now sign in to{" "}
            {isDevelopment
              ? "account.twinbuild.dev"
              : "account.twinbuild.com"}{" "}
            with {userEmail} via any sign in method.
          </Typography>
          <Box>
            <Table size="small">
              <TableHead>
                <TableCell>Item</TableCell>
                <TableCell>Value</TableCell>
              </TableHead>
              <TableRow>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>{userEmail}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>{userName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Team</strong>
                </TableCell>
                <TableCell>{teamName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Stripe Id</strong>
                </TableCell>
                <TableCell>{response.customer_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>User Id</strong>
                </TableCell>
                <TableCell>{response.user_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Team Id</strong>
                </TableCell>
                <TableCell>{response.team_id}</TableCell>
              </TableRow>
            </Table>
          </Box>
          <Box sx={styles.fields}>
            <Button
              sx={styles.field}
              variant="outlined"
              color="primary"
              endIcon={<Launch />}
              href={`https://dashboard.stripe.com/${
                isDevelopment ? "test/" : ""
              }customers/${response.customer_id}`}
              target="_blank"
            >
              Open Stripe
            </Button>
          </Box>
        </>
      )}
    </AccountCollapse>
  );
}

const styles = {
  title: {
    marginTop: 0.75,
  },
  subtitle: {
    marginBottom: 2.25,
  },
  stepper: {
    backgroundColor: "inherit",
    margin: 2,
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 1,
  },
  field: {
    flexGrow: 10,
    flexBasis: 200,
    height: "auto",
    margin: 0.25,
  },
} as const;
