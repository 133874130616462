import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import firebase from "firebase/compat/app";
import { useStripe } from "../StripeProvider";
import { Team } from "Types";
import { ensureRecentAuth, useClaims } from "Auth";
import { useAlerts } from "Alerts";
import WorkerButton from "components/WorkerButton";
import AccountCollapse from "./AccountCollapse";
import { Launch } from "@mui/icons-material";
import ms from "ms";

const functions = firebase.functions();

const STRIPE_CHECKOUT_FUNCTION = "stripeCheckoutSession";

type CheckoutRequest = {
  success_url: string;
  cancel_url: string;
  price_id: string;
  quantity?: number;
};

type CheckoutResponse = {
  sessionId: string;
};

export type Currency = "USD" | "GBP" | "AUD" | "EUR";

type FetchPriceResponse = {
  prices: Price[];
};

type Price = {
  price_id: string;
  interval: string;
  amount: number;
  currencyCode: Currency;
  currencySymbol: string;
};

async function fetchPriceFromServer() {
  return (await functions.httpsCallable("appFetchPrices")())
    .data as FetchPriceResponse;
}

function useLocalizedPrice() {
  const [price, setPrice] = useState<FetchPriceResponse | null>();

  useEffect(() => {
    const cached = localStorage.getItem("localized_prices");
    if (cached != null) {
      setPrice(JSON.parse(cached) as FetchPriceResponse);
    }
    let cancelled = false;
    (async () => {
      try {
        const fetched = await fetchPriceFromServer();
        localStorage.setItem("localized_prices", JSON.stringify(fetched));
        setPrice(fetched);
      } catch (err) {
        console.error("Error fetching localized prices.", err);
        setPrice(null);
      }
      if (cancelled) return;
    })();

    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    if (price) {
      console.info("Price", price);
    }
  }, [price]);

  return price?.prices;
}

/**
 * Only visible if claims.team_id == null, i.e. not in a team
 * OR
 * there are no subscriptions attached to the team
 */

export default function CheckoutView({ team }: { team: Team | null }) {
  const stripe = useStripe();
  const claims = useClaims();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [seatsCount, setSeatCount] = useState<number>(1);
  const [working, setWorking] = useState<boolean>(false);
  const alerts = useAlerts();

  const prices = useLocalizedPrice();

  const price = useMemo(() => {
    if (selectedIndex !== undefined && prices) {
      return prices[selectedIndex];
    }
    return undefined;
  }, [prices, selectedIndex]);

  const launchCheckout = useCallback(async () => {
    if (!stripe) return;
    if (!price) return;

    setWorking(true);
    if (!(await ensureRecentAuth(ms("1h"), alerts))) {
      setWorking(false);
      return;
    }

    const request: CheckoutRequest = {
      success_url: `${window.location.origin}/redirect?url=${encodeURIComponent(
        window.location.pathname
      )}&title=${encodeURIComponent("Checkout Successful")}`,
      cancel_url: window.location.toString(),
      price_id: price.price_id,
      quantity: seatsCount,
    };

    try {
      const response = await firebase
        .functions()
        .httpsCallable(STRIPE_CHECKOUT_FUNCTION)(request);

      if (!response.data) throw new Error("Unable to open checkout portal");

      const session = (response.data as CheckoutResponse).sessionId;

      await stripe.redirectToCheckout({ sessionId: session });
    } catch (err) {
      console.error(err);
      await alerts.alert("Unable to open checkout");
      setWorking(false);
    }
  }, [price, alerts, seatsCount, stripe]);

  if (claims?.isInATeam && !claims.isFinanceManagerOrAbove) return null;

  if (team != null && (team.total_subscription_quantity ?? 0) > 0) {
    //console.info("Checkout hidden as team has existing subscription items");
    return null;
  }

  return (
    <AccountCollapse
      title="Add Subscription"
      description="Add a subscription to your Twinbuild account"
      expand={prices != null}
    >
      <Box sx={styles.spacer} />
      <InputLabel shrink>Plan</InputLabel>
      <Typography variant="h4" color="primary" sx={styles.text}>
        Twinbuild Pro Plan
      </Typography>
      <Box sx={styles.planInformation}>
        <Button
          color="primary"
          endIcon={<Launch />}
          href="https://twinbuild.com/#pricing"
          target="_blank"
        >
          View Features
        </Button>
      </Box>

      {price != null && prices != null && (
        <>
          <Box sx={styles.priceDescription}>
            <Typography variant="h5" sx={styles.price}>
              <strong>
                {price.currencySymbol}
                {(price.interval === "year"
                  ? price.amount / 12
                  : price.amount
                ).toLocaleString()}
              </strong>
            </Typography>
            <Typography
              variant="h5"
              sx={{ ...styles.price, ...styles.currency }}
            >
              {price.currencyCode}
            </Typography>
            <Typography variant="body1"> per HoloLens 2 per month</Typography>
          </Box>
          <Typography variant="caption">
            {price.interval === "year" && (
              <>
                {price.currencySymbol}
                {price.amount.toLocaleString()}
                {price.currencyCode} billed anually
              </>
            )}
            {price.interval === "month" && <>billed monthly</>}
          </Typography>

          <Box sx={styles.actionContainer}>
            <TextField
              sx={{ ...styles.control, ...styles.number }}
              type="number"
              label="Seats"
              variant="outlined"
              size="small"
              inputProps={{
                min: 1,
                max: 99,
                step: 1,
                style: {
                  textAlign: "center",
                },
              }}
              value={seatsCount}
              onChange={(ev) => setSeatCount(parseInt(ev.target.value))}
            />

            <FormControl sx={{ ...styles.control, minWidth: 120 }}>
              <InputLabel
                variant="outlined"
                id="plan-select-label"
                htmlFor="plan-select"
              >
                Billed
              </InputLabel>
              <Select
                labelId="plan-select-label"
                label="Billed"
                MenuProps={{
                  disableScrollLock: true,
                }}
                variant="outlined"
                size="small"
                value={selectedIndex}
                onChange={(change) =>
                  setSelectedIndex(change.target.value as number)
                }
              >
                {prices.map((price, index) => (
                  <MenuItem value={index} key={index}>
                    {price.interval + "ly"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <WorkerButton
              sx={{ ...styles.control, ...styles.button }}
              variant="contained"
              color="primary"
              working={working}
              onClick={() => launchCheckout()}
            >
              {working ? "Redirecting..." : "Subscribe"}
            </WorkerButton>
          </Box>
        </>
      )}
    </AccountCollapse>
  );
}

const styles = {
  actionContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    maxWidth: 300,
    marginTop: 1.5,
  },
  number: {
    minWidth: 70,
  },
  button: { width: "100%", minWidth: 140 },
  control: {
    margin: 0.5,
    marginLeft: 0,
  },
  planInformation: { display: "block", maxWidth: 300 },
  spacer: {
    marginTop: 2,
  },
  priceDescription: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: 1.5,
  },
  currency: {
    marginRight: 0.25,
    fontWeight: 100,
  },
  text: {
    margin: 0,
  },
  price: {
    marginBottom: 0,
  },
} as const;
